<template>
<div class="dropdown-furniture-items">
  <template v-for="(item, index) in itemsGrid">
    <div class="dropdown-furniture-item">
      <nuxt-link
        :to="item.url"
        class="dropdown-furniture-item__head"
        @click="() => clickLink(item.url)"
      >
        <div class="dropdown-furniture-item__icon">
          <nuxt-img
            v-if="item.icon"
            :src="String(item.icon)"
            width="58px"
            height="58px"
            loading="lazy"
          />
        </div>
        <div class="dropdown-furniture-item__title">
          {{ item.title }}
        </div>
      </nuxt-link>
      <div
        class="dropdown-furniture-item__list"
        :class="{'--full': openedIndex.includes(index)}"
      >
        <nuxt-link
          v-for="(itemUrl) in item.list"
          :to="itemUrl.url"
          @click="() => clickLink(itemUrl.url)"
        >
          {{ itemUrl.label }}
        </nuxt-link>
      </div>
      <div
        v-if="item.list.length > 5"
        class="dropdown-furniture-item__more"
        :class="{'--full': openedIndex.includes(index)}"
        @click="() => changeOpenIndex(index)"
      >
        {{ openedIndex.includes(index) ? 'Свернуть назад' : 'Показать все' }}
        <img src="~/assets/img/svg/common/arrow-down.svg"/>
      </div>
    </div>
  </template>
</div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';

interface IItemList {
  label: string,
  url: string
}
interface IItemGrid {
  icon: string | number,
  url: string,
  title: string,
  list: IItemList[]
}

const props = defineProps({
  item: {
    type: Object,
    default: () => {
      return {}
    }
  }
});
const emits = defineEmits(['clickChildren']);

const itemsGrid: ComputedRef<IItemGrid[]> = computed(() => {
  const children = (props?.item?.children || [])
    .filter((t: any) => Boolean(t?.showInMenu));

  return children.map((t: any) => {
    const _list = (t?.children || []).map((_item: any) => {
      return {
        url: _item?.url,
        label: _item?.name,
      }
    });

    return {
      url: t?.url || '',
      icon: String(t?.dynamicFields?.menu_icon || ''),
      title: t?.name || '',
      list: _list
    }
  })
});

const clickLink = (newUrl?: string) => {
  emits('clickChildren', newUrl);
}

const openedIndex: Ref<number[]> = ref([]);
const changeOpenIndex = (index: number) => {
  let _openedIndex = openedIndex.value;
  const findIndex = _openedIndex.findIndex((t: number) => t === index);
  if (findIndex > -1) {
    _openedIndex.splice(findIndex, 1)
  } else {
    _openedIndex.push(index)
  }
  openedIndex.value = _openedIndex;
};

</script>

<style scoped lang="scss">
.dropdown-furniture-items {
  display: flex;
  flex-wrap: wrap;
  margin-right: -6px;
  max-height: calc(100vh - 180px);
  overflow: auto;

  & > * {
    width: calc(100% / 5);
    margin-top: -1px;
    margin-left: -1px;
  }
}

.dropdown-furniture-item {
  border: 1px solid #e6e6e6;
  border-bottom: none;
  padding: 16px 35px;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.dropdown-furniture-item__head {
  &:hover {
    .dropdown-furniture-item__title {
      text-decoration: underline;
      box-shadow: 0 0 0 1px transparent;
    }
  }
}
.dropdown-furniture-item__icon {
  display: flex;
  width: 100%;
  max-width: 58px;
  position: relative;
  margin: 0 auto;

  img {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }
  &:after {
    content: "";
    float: left;
    padding-top: 100%;
  }
}
.dropdown-furniture-item__title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 11px;

  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #000000;
}
.dropdown-furniture-item__list {
  display: flex;
  flex-direction: column;

  & > * {
    padding: 5px 0;
    box-sizing: border-box;
    display: none;

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #989898;

    &:hover {
      color: black;
    }
    &:nth-child(-n+5) {
      display: block;
    }
  }
  &.--full > * {
    display: block;
  }
}
.dropdown-furniture-item__more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #000000;

  img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    object-fit: contain;
  }
  &.--full img {
     transform: rotate(180deg);
  }
}

@media (max-width: 1359px) {
  .dropdown-furniture-items > * {
    width: calc(100% / 4);
  }
}
@media (max-width: 1199px) {
  .dropdown-furniture-items > * {
    width: calc(100% / 3);
  }
}
</style>
